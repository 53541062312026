import React from 'react';
import {graphql} from 'gatsby';
import Page from '../../site/layouts/Page';
import SEO from '../SEO/SEO';
import ArticlesListTemplate from './ArticlesListTemplate';
import PaginationEx from '../PaginationEx';
import TaxonomyPageTitle from '../TaxonomyPageTitle';

const ArticleTagArticles = props => {
  const {
    data: {
      ahp: {getArticleTag: tag},
    },
    pageContext,
  } = props;
  const {
    currentPage,
    hasNextPage,
    hasPreviousPage,
    baseLink,
    pagedLink,
  } = pageContext;
  const path = pagedLink || `/article-tags/${tag.slug}`;
  return (
    <Page sidebar="articles">
      <SEO
        title={`${tag.tag} Articles, News, and Resources`}
        description={`Blog articles tagged "${tag.tag}" in Austin, Cedar Park, Leander, Round rock, Pflugerville, Manor, Dripping springs Texas`}
        pathname={path}
        keywords={[
          `${tag.tag} blog articles`,
          'Real estate topics',
          'Help selling my home',
          'Help buying a house in Texas',
          'Angie Ufomata',
        ]}
      />
      <TaxonomyPageTitle leadText="Articles Tagged">
        {tag.tag}
      </TaxonomyPageTitle>
      <ArticlesListTemplate articles={tag.articles.nodes} />
      <PaginationEx
        indeterminate
        baseLink={baseLink || pagedLink}
        currentPage={currentPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
      />
    </Page>
  );
};

export default ArticleTagArticles;

export const query = graphql`
  query ArticleTagArticles($tagId: ID!, $startCursor: String, $perPage: Int!) {
    ahp {
      getArticleTag(id: $tagId) {
        tag
        articles(paginate: {after: $startCursor, first: $perPage}) {
          ...ArticleListItemFragment
        }
      }
    }
  }
`;
